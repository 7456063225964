import { graphql, PageProps } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Certification from '../../components/academy/Certification';
import FindOutMore from '../../components/academy/FindOutMore';
import Introduction from '../../components/academy/Introduction';
import LinkedArticles from '../../components/academy/LinkedArticles';
import LinkedDocuments from '../../components/academy/LinkedDocuments';
import NextEvents from '../../components/academy/NextEvents';
import Questions from '../../components/academy/Questions';
import WeTalk from '../../components/academy/WeTalk';
import DefaultLayout from '../../components/layouts/default-layout';
import * as Colors from '../../components/Colors';
import { AllMdxPost, graphql2post, PageContextProps, SiteQueryProps } from '../../utils/graphql';
import { AllGraphqlAcademy } from '../../utils/graphqlNotion';
import { useLocalizedItem } from '../../utils/use-localized-items';

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allNotionAcademy(
      filter: { properties: { start_date: { ne: null } } }
      sort: { order: ASC, fields: properties___start_date }
    ) {
      nodes {
        slug
        locale
        properties {
          i18n_key
          status
          name
          start_date
          end_date
          language
          teachers
          place
          abstract
        }
      }
    }
    allMdx(
      filter: {
        fields: { source: { eq: "news" }, slug: { ne: null } }
        frontmatter: {
          type: { eq: "Article" }
          Published: { eq: true }
          displayInAcademyPage: { eq: true }
        }
      }
      sort: { fields: [fields___date, fields___slug, fields___locale], order: DESC }
      limit: 4
    ) {
      nodes {
        timeToRead
        excerpt
        frontmatter {
          title
          abstract
          duration
          type
          tags
          theme
          externalUrl
        }
        fields {
          slug
          date
          locale
          socialImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 432, backgroundColor: "transparent")
            }
          }
        }
      }
    }
  }
`;

type AcademyIndexQueryProps = {
  site: SiteQueryProps;
  allNotionAcademy: AllGraphqlAcademy;
  allMdx: AllMdxPost;
};

type AcademyIndexProps = PageProps<AcademyIndexQueryProps, PageContextProps>;

const AcademyIndex: React.VoidFunctionComponent<AcademyIndexProps> = ({
  data: {
    site,
    allNotionAcademy: { nodes: allEvents },
    allMdx: { nodes: allPosts },
  },
  pageContext,
}) => {
  const MAX_DISPLAY_ARTICLES = 2;
  const posts = useLocalizedItem(
    allPosts.filter((post) => !!post.frontmatter).map(graphql2post),
  ).slice(undefined, MAX_DISPLAY_ARTICLES);
  const events = useLocalizedItem(allEvents);
  const siteUrl = site.siteMetadata.siteUrl;
  const { t } = useTranslation('academy');
  const bookingUrl =
    'https://outlook.office365.com/owa/calendar/Reacteev@reacteev.com/bookings/s/rqgKx30VdE2TSP4iGT9wrQ2';

  return (
    <DefaultLayout
      headTitle={t('head-title')}
      headDescription={t('head-description')}
      lang={pageContext.locale}
      theme={{ class: Colors.WHITE_ON_BLACK, pseudoClass: Colors.WHITE_ON_BLACK_PSEUDO_CLASS }}
      preserveNavbarSpace={false}
      showContactForm={true}
    >
      <Introduction />
      <FindOutMore hideButtonCall={events.length === 0} bookingUrl={bookingUrl} />
      <NextEvents locale={pageContext.locale} events={events} />
      <WeTalk bookingUrl={bookingUrl} />
      <LinkedArticles posts={posts} siteUrl={siteUrl} pageContext={pageContext} />
      <Questions />
      <Certification />
      <LinkedDocuments />
    </DefaultLayout>
  );
};

export default AcademyIndex;
